$(document).ready(function() {
	$('.godown').click(function() {
		$(this).fadeOut();
		$('html, body').animate({ scrollTop: ($(window).height())}, 2000);
	});
	/**
	 * Parallax
	 */
	$(window).enllax();
});

$(window).load(function() {
	/**
	 * Logo Auto Slider
	 */
	var $marqueeInner = $('.logo-marquee ._inner');
	var smoothDuration;
	var totalMove;

	var setMarqueeWidth = function() {
		var totalWidth = 0;
		var windowWidth = $(window).width();

		// get full width
		$marqueeInner.children().each(function() {
			totalWidth += $(this).outerWidth();
		});

		// process totalWidth
		smoothDuration = Math.round(totalWidth / windowWidth * 10);
		totalMove = totalWidth - windowWidth;

		// set animation styles
		$marqueeInner.css({
			"width" : totalWidth,
			"margin-left" : "-"+totalMove+"px",
			"transition-duration" : smoothDuration+"s"
		})
	};

	setMarqueeWidth();

	$marqueeInner.onCSSTransitionEnd(function() {
		$(this).toggleClass('reset');
	});

	/**
	 * Resize
	 */
	$(window).on('resize', _.debounce(setMarqueeWidth, 300));
});